import { Component, OnInit, ViewChild, ElementRef } from "@angular/core";
import { Router } from "@angular/router";
import { AccountService } from "app/services/AccountService";


@Component({
    selector: 'app-terms',
    templateUrl: './terms.component.html',
    styleUrls: ['./terms.component.css']
})
export class TermsComponent implements OnInit {

    constructor(private accountService: AccountService, private router: Router) {
    }

    ngOnInit(): void {
        this.accountService.menu = "terms";
        window.scroll(0, 0);
    }

    getMenuStyle(menu) {
        if (this.accountService.menu === 'terms' && menu === 'home') {
            return {
                'cursor': 'pointer',
                'color': 'black',
                'font-family': 'allison',
                'font-size': '40px',
                'text-transform': 'none'
            }
        } else {
            return {
                'cursor': 'pointer',
                'color': 'white'
            }
        }
    }

    goTo(path) {
        if (path === "privacy") {
            this.router.navigate(['/privacy']);
        } else if (path === "terms") {
            this.router.navigate(['/terms']);
        }
    }
}