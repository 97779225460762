import { Injectable } from "@angular/core";
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpErrorResponse } from "@angular/common/http";
import { Router } from "@angular/router";
import { Observable, throwError } from "rxjs";
import { catchError } from 'rxjs/operators';
import { AccountService } from "./services/AccountService";


@Injectable()
export class AuthInterceptor implements HttpInterceptor {

    constructor(private router: Router,
        private accountService: AccountService) {
    }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(req).pipe(catchError(err => {
            if (err instanceof HttpErrorResponse) {
                if (err['status'] == 405 || err['status'] == 401) {
                    // this.accountService.clearSession();
                    // localStorage.clear();
                    // this.router.navigate(["/"]);
                }
                return throwError(err);
            }
        }));
    }

}