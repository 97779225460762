import { Injectable } from "@angular/core";
import { Title, Meta } from "@angular/platform-browser";

@Injectable()
export class SEOService {
    constructor(private title: Title, private meta: Meta) { }

    updateTitle(title: string) {
        this.title.setTitle(title);
    }

    updateTag(name: string, content: string) {
        this.meta.updateTag({ property: name, content: content });
    }
}