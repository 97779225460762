import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { AccountService } from "app/services/AccountService";
import { environment } from "environments/environment";

@Component({
    selector: 'app-landing',
    templateUrl: './landing.component.html',
    styleUrls: ['./landing.component.scss']
})
export class LandingComponent implements OnInit {

    serviceMenu: any = null;
    currentDate = new Date();

    constructor(private modalService: NgbModal, private router: Router, public accountService: AccountService) {
        this.accountService.menu = "home";
    }

    ngOnInit(): void {
    }

    toggleService(menu) {
        this.serviceMenu = menu;
    }

    getMenuStyle(menu) {
        if (this.accountService.menu === menu && menu !== 'home') {
            return {
                'cursor': 'pointer',
                'color': '#EC643C'
            }
        } else if (this.accountService.menu === menu && menu === 'home') {
            return {
                'cursor': 'pointer',
                'color': 'black',
                'font-family': 'allison',
                'font-size': '40px',
                'text-transform': 'none'
            }
        } else {
            return {
                'cursor': 'pointer',
                'color': 'black'
            }
        }
    }

    goTo(path) {
        if (path === "privacy") {
            this.router.navigate(['/privacy']);
        } else if (path === "terms") {
            this.router.navigate(['/terms']);
        }
    }

    scrollToElement($element): void {
        $element.scrollIntoView({ behavior: "smooth", block: "start", inline: "nearest" });
    }
}