import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { environment } from "environments/environment";

@Injectable()
export class AccountService {

    menu = "home";

    constructor(private httpClient: HttpClient, private router: Router) {
    }
    
    private isEmpty(value) {
        if (value == undefined || value == null || value === "") {
            return true;
        }
        return false;
    }

}