import { Component, OnInit, Inject, Renderer2, ElementRef, ViewChild } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { Subscription } from 'rxjs/Subscription';
import 'rxjs/add/operator/filter';
import { DOCUMENT, DatePipe } from '@angular/common';
import { LocationStrategy, PlatformLocation, Location } from '@angular/common';
import { AccountService } from './services/AccountService';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { environment } from 'environments/environment';
import * as AOS from 'aos';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {

    image: any = null;
    index: number = 0;

    isCookies: boolean = true;

    private _router: Subscription;

    langForm: FormGroup;

    currentDate = new Date();

    constructor(private renderer: Renderer2, private router: Router, @Inject(DOCUMENT,) private document: any, private element: ElementRef, public location: Location,
        private modalService: NgbModal,
        private datepipe: DatePipe,
        //private seoService: SEOService,
        private accountService: AccountService) {
        let URL = window.location.href;
        if (environment.production == true && URL.indexOf("https") < 0 || URL.indexOf("www") < 0) {
            window.location.href = "https://www.alephconsulting.mu";
        }
    }

    acceptCookie() {
        localStorage.setItem("cookies", "true");
        this.isCookies = true;
    }


    ngOnInit() {
        AOS.init();
    }
} 
