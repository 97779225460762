import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { RouterModule } from '@angular/router';
import { AppRoutingModule } from './app.routing';

import { AppComponent } from './app.component';

import { LandingComponent } from './landing/landing.component';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { HttpModule } from '@angular/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NouisliderModule } from 'ng2-nouislider';
import { PrivacyComponent } from './privacy/privacy.component';
import { DatePipe } from '@angular/common';
import { JwBootstrapSwitchNg2Module } from 'jw-bootstrap-switch-ng2';
import { AuthInterceptor } from './auth.interceptor';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { AccountService } from './services/AccountService';
import { SEOService } from './services/SEOService';
import { environment } from 'environments/environment';
import { TermsComponent } from './terms/terms.component';

@NgModule({
    declarations: [
        AppComponent,
        LandingComponent,
        PrivacyComponent,
        TermsComponent
    ],
    imports: [
        BrowserModule,
        NgbModule,
        FormsModule,
        RouterModule,
        AppRoutingModule,
        NouisliderModule,
        HttpModule,
        HttpClientModule,
        BrowserAnimationsModule,
        ReactiveFormsModule,
        JwBootstrapSwitchNg2Module,
        CarouselModule,
    ],
    providers: [DatePipe, AccountService, SEOService,
        {
            provide: HTTP_INTERCEPTORS,
            useClass: AuthInterceptor,
            multi: true
        }],
    bootstrap: [AppComponent]
})
export class AppModule { }
